import { collection } from "firebase/firestore";
import { collectionDocument } from "@/core-nextv3/document/document.api"
import { CASINO_SETTING } from "@/setting/setting"

export async function getCasinoProviders() 
{
    const result = await collectionDocument(
        CASINO_SETTING.merge({
            perPage : 100,
            where   : [
                {
                    field    : "_level",
                    operator : "==",
                    value    : 1,
                },
                // {
                //     field    : "status",
                //     operator : "==",
                //     value    : true,
                // },
            ],
        })
    )


    const providers: any[] = []

    if (result?.collection) 
    {
        result?.collection.forEach((item: any) => 
        {
            if (item.id.startsWith("2")) 
            {
                providers.push(item)
            }
        })
    }

    return providers
}